<template>
    <div class="edit-profile-detail">
        <StackRouterHeaderBar left-button="close" title="가족관계" :showTitle="true" />
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div class="section">
            <div class="section-title required">부모 결혼 상태</div>
            <div class="f-14 m-b-16">해당 정보는 매칭 참고용으로 매니저에게만 공유됩니다.</div>
            <div class="column">
                <div
                    class="item"
                    :class="{ selected: idx === selectedIdx }"
                    v-for="(item, idx) in parentStatus"
                    :key="item.id"
                    v-html="item.title"
                    @click="onClickItem(idx)"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditProfileDetailParentStatus',
    props: ['type'],
    data: () => ({
        selectedIdx: null,
    }),
    computed: {
        parentStatus() {
            return Object.keys(this.$profile.parentStatus).map(key => this.$profile.parentStatus[key])
        },
        profile() {
            return this.$store.getters.profile || []
        },
    },
    methods: {
        onClickItem(idx) {
            this.selectedIdx = idx

            setTimeout(this.submit, 200)
        },
        submit() {
            this.$set(this.profile, 'parent_status', this.selectedIdx)
            this.$stackRouter.pop()
        },
    },
}
</script>

<style scoped></style>
